import { render } from "./inputPhoneNumber.vue?vue&type=template&id=2035d9ec&scoped=true"
import script from "./inputPhoneNumber.vue?vue&type=script&setup=true&lang=js"
export * from "./inputPhoneNumber.vue?vue&type=script&setup=true&lang=js"

import "./inputPhoneNumber.vue?vue&type=style&index=0&id=2035d9ec&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-2035d9ec"
/* hot reload */
if (module.hot) {
  script.__hmrId = "2035d9ec"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2035d9ec', script)) {
    api.reload('2035d9ec', script)
  }
  
  module.hot.accept("./inputPhoneNumber.vue?vue&type=template&id=2035d9ec&scoped=true", () => {
    api.rerender('2035d9ec', render)
  })

}

script.__file = "src/views/verifyCode/inputPhoneNumber.vue"

export default script